.questions_listing_main_container {
	.button_container {
		text-align: right;
		margin-bottom: 10px;
	}
	.questions_listing_content_container {
		overflow: auto;
		.questions_listing_box {
			padding: 20px;
			margin-bottom: 10px;
			border-radius: 8px;
			background-color: var(--white);
			box-shadow: 0px 0px 5px 0px var(--boxShadow) inset;
			.qn_edit_delete_container {
				> span {
					.edit_icon {
						max-width: 15px;
					}
					.delete_icon {
						max-width: 18px;
					}
				}
			}
			.answer_content_container {
				width: 100%;
				display: inline-block;
				margin-top: 20px;
				.answer_box {
					position: relative;
					width: 47%;
					display: inline-block;
					vertical-align: top;
					margin: 8px 0px;
					border-radius: 6px;
					padding: 10px 20px;
					border: 1px solid var(--answerBoxBorder);
					background-color: var(--white);
					box-shadow: 0px 0px 2px 0px var(--boxShadow);
					&.correct_answer {
						background-color: var(--answerColor);
					}
					&.wrong_answer{
						background-color: #f9ccbd;
					}
				}

			}
		}
		.attachment_container {
			width: 100%;
			height: 300px;
			text-align: center;
			> img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}
.user_details{
	position: sticky;
  	top: 0;
	box-shadow: none;
	border: 1px solid #ccc;
	padding: 20px;
	margin-bottom: 10px;
	border-radius: 8px;
	background-color: #fff;
	z-index: 9;
	ul{
		padding-left: 0;
  		width: auto;
		margin-bottom: 5px;
		li{
			list-style: none;
			font-size: 16px;
			label{
				color: #f15623;
				padding-right: 1px;
			}
			span{
				color: #504f4e;
				padding-left: 5px;
			}
		}
	}
	.view-more{
		font-size: 14px;
		color: #090909;
		cursor: pointer;
		
	}
	.view-more:hover{
		text-decoration: underline;
	}

}
.selection-mark{
	position: absolute;
	right: 0;
	padding: 0px;
	background: #2d9d15;
	border-radius: 0px 6px 5px 0px;
	top: 0px;
	color: #f9f9f9;
	width: 41px;
}