.generate_link_box_content {
	padding: 0 20px;
	.generate_link_sub_header {
		color: var(--mandatoryTextColor);
		font-family: "regular";
	}
	.generate_link {
		width: 100%;
		padding: 5px 10px;
		border-radius: 4px;
		border: 1px solid var(--formBorder);
		background-color: var(--mainBodyBg);
		color: var(--mandatoryTextColor);
		user-select: all
	}
}
.upload_csv{
		margin-top: 30px;
		display: flex;
		position: relative;
	.upload_button{

		float: left;
		width: auto;
		color: #5e5b5b;
		cursor: pointer;
		.file_overlap{
			float: left;
			padding: 4px;
			border: 2px solid #c0c0c1;
			border-radius: 5px;
			position: relative;
			text-align: center;
			.uploadInput {
				visibility: visible;
			}
			.icon_upload{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}
		.file_name{
			float: left;
			padding: 5px;
		}
		}
	.send_button{
		position: absolute;
		right: 0px;
		span {
			float: left;
  			margin-left: -14px;
		}
		svg {
			stroke: #f15623;
			fill: #f15623;
		}
	}
	.send_button[disabled] {
		pointer-events: none; /* Optional: to prevent clicks */
		opacity: 0.5; /* Optional: to show it's disabled */
	}
	
	.send_button[disabled] svg {
		stroke: #f7a990;
		fill: #f7a990;
	}
	.close_button{
		border: 0px;
		background: unset;
		padding: 5px;
		cursor: pointer;
		font-weight: 599;
		color: #fb490f;
	}
}