.registration_main_container {
	height: 100%;
	width: 100%;
	.registration_bg_container {
		width: 30%;
		height: 100%;
		display: inline-block;
		background-image: url("../../../public/images/registration-bg.png");
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
		.application_title_container {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.application_main_title {
				font-size: 54px;
				font-family: "arvo-regular";
				color: var(--white);
				text-shadow: 2px 1px var(--themeColor);
				line-height: 65px;
			}
			.application_sub_title {
				min-width: 330px;
				text-align: right;
				color: var(--white);
				font-size: 15px;
				font-family: "regular";
			}
		}
	}
	.registration_card_container {
		width: calc(100% - 30%);
		height: 100%;
		display: inline-block;
		vertical-align: top;
		.registration_card_content_container {
			.logo_img {
				max-height: 26px;
			}
			.registration_card {
				margin: 0 60px 60px 0;
				box-shadow: 0px 0px 9px 0px var(--boxShadow);
				overflow: auto;
				padding: 10px 0;
				border-radius: 0 6px 6px 0px;
				display: flex;
				flex-direction: column;
				padding: 30px;
				@media screen and (max-height: 750px) {
					margin: 0 30px 30px 0;
					padding: 15px;
				}
			}
		}
	}
}
.initial_loading {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--mainBodyBg);
	color: var(textColor);
	z-index: 99;
}
