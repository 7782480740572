.custom_main_form {
	.mandatory_text_note {
		color: var(--mandatoryTextColor);
	}
}
.attachment_img_box {
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	border: 1px solid var(--inputBoxBorder);
	background: var(--mainBodyBg);
	color: var(--textColor);
	font-size: 13px;
	margin-top: 5px;
	.attachment_img_container {
		.clear_icon_svg {
			> svg {
				width: 10px;
				height: 10px;
			}
		}
	}
}
