.result_listing_main_container {
	.filter_container {
		margin-bottom: 10px;
		.custom_input_field_container {
			.search_icon_svg {
				position: absolute;
				right: 8px;
				top: 9px;
				cursor: pointer;
			}
		}
		.clear_all {
			color: var(--themeColor);
		}
	}
	.incomplete{
		color: "#f75d2b";
	}
}
