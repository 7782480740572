.student_exam_container {
	flex: 1 1 10px;
	.student_exam_content_box {
		padding: 30px;
		.exam_question_box_container {
			margin-bottom: 25px;
		}
		.exam_answer_box_container {
			.exam_answer_box {
				cursor: pointer;
				margin: 10px 0px;
				border-radius: 6px;
				padding: 10px 20px;
				border: 1px solid var(--answerBoxBorder);
				background-color: var(--white);
				box-shadow: 0px 0px 2px 0px var(--boxShadow);
				&.answer {
					background-color: var(--answerColor);
				}
			}
			.exam_answer_box:hover {
				border: 1px solid var(--themeColor);
				box-shadow: 0px 0px 4px 0px var(--boxShadow);
			}
		}
		.exam_img_attachment_container {
			width: 100%;
			height: 250px;
			text-align: center;
			> img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}

.fullscreen-section {
	width: 100vw; height: 100vh; background: "#f0f0f0";
}