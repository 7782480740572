.timer_box {
	display: inline-block;
	text-align: center;
	.timer_text {
		font-size: 17px;
		font-family: "medium";
	}
	.timer {
		padding: 5px 10px;
		border-radius: 4px;
		color: var(--white);
		background-color: var(--primaryGreen);
		&.alert_timer {
			background-color: #e23813;
		}
	}
}
