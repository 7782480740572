:root {
	--themeColor: #f15623;
	--boxShadow: rgba(0, 0, 0, 0.25);
	--mandatoryTextColor: #767476;
	--white: #ffffff;
	--black: #1a171b;
	--mainBodyBg: #f7f9fa;
	--textColor: #4a4a4a;
	--formBorder: rgba(163, 162, 164, 0.65);
	--invalid: #f65f5f;
	--tableHeader: #747576;
	--answerBoxBorder: rgba(74, 74, 74, 0.21);
	--mainBgDivider: #eeeeee;
	--answerColor: #ceeddb;
	--inputBoxBorder: #aeaeae;
	--disabledFormField: #f5f5f5;
	--secondaryTextColor: #484549;
	--primaryGreen: #659d7b;
	--themeLightBorder: rgba(241, 86, 35, 0.39);
}
