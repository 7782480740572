.login_main_container {
	height: 100%;
	width: 100%;
	.login_bg_container {
		width: 60%;
		height: 100%;
		display: inline-block;
		background-image: url("../../../public/images/login_bg.png");
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
		.application_title_container {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.application_main_title {
				font-size: 54px;
				font-family: "arvo-regular";
				color: var(--white);
				text-shadow: 2px 1px var(--themeColor);
				line-height: 65px;
			}
			.application_sub_title {
				min-width: 330px;
				text-align: right;
				color: var(--white);
				font-size: 15px;
				font-family: "regular";
			}
		}
	}
	.login_card_container {
		width: 40%;
		height: 100%;
		display: inline-block;
		vertical-align: top;
		.login_card_content_container {
			.logo_img {
				max-height: 26px;
			}
			.login_card {
				margin: 0 60px 60px 0;
				box-shadow: 0px 0px 9px 0px var(--boxShadow);
				overflow: auto;
				padding: 10px 0;
				border-radius: 0 6px 6px 0px;
				@media screen and (min-width: 1800px) {
					max-width: 600px;
				}
				.login_card_content {
					height: 100%;
					overflow: auto;
					padding: 30px 60px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.login_heading {
						font-size: 26px;
						font-family: "medium";
						color: var(--black);
					}
					.login_input_container {
						position: relative;
						.login_input_label {
							color: var(--textColor);
						}
						.input_field {
							border: 1px solid var(--formBorder);
							border-radius: 4px;
							box-shadow: none;
							min-height: 36px;
							outline: none;
							padding: 0 10px;
							width: 100%;
							background-color: var(--mainBodyBg);
						}
						.password_eye_container {
							.input_field {
								padding-right: 35px;
							}
							.password_eye {
								color: var(--textColor);
								cursor: pointer;
								position: absolute;
								right: 10px;
								top: 8px;
							}
						}
					}
				}
			}
		}
	}
}
