.result_listing_main_container {
	.filter_container {
		margin-bottom: 10px;
		.custom_input_field_container {
			.search_icon_svg {
				position: absolute;
				right: 8px;
				top: 9px;
				cursor: pointer;
			}
		}
		.clear_all {
			color: var(--themeColor);
		}
	}
	.modal-form{
		padding: 0px 16px;
	}
}
.add-collage {
	.ant-modal-body {
		padding: 0px 14px;
		.ant-form-item-explain-error {
			font-size: 12px;
		}
	}
}
