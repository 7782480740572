.custom_header {
	height: var(--headerHeight);
	flex: 0 0 var(--headerHeight);
	.header_logo_container {
		width: var(--sidebarWidth);
		height: 100%;
		flex: 0 0 var(--sidebarWidth);
		padding: 15px 30px;
		position: relative;
		box-shadow: 0px 2px 4px 0px var(--boxShadow);
		.main_logo_container {
			text-align: center;
			.logo_img {
				max-height: 26px;
			}
		}
		.logo_sub_header {
			text-align: right;
			padding-right: 7px;
			font-family: "arvo-regular";
			font-size: 16px;
			color: var(--black);
			line-height: 18px;
		}
		&.header_logo_container::after {
			content: "";
			position: absolute;
			left: 15px;
			right: 15px;
			bottom: 0;
			height: 1px;
			background-color: var(--mainBgDivider);
		}
	}
	.custom_main_heading {
		font-family: "medium";
		padding-left: 30px;
	}
	.select_count_details {
		padding-right: 30px;
		.count_number {
			color: var(--themeColor);
		}
	}
}
