.registration_main_form {
	width: 100%;
	overflow: auto;
	padding: 10px;
	display: flex;
	flex-direction: column;
	.registration_header_text {
		font-size: 26px;
		font-family: "medium";
		color: var(--black);
		padding-bottom: 20px;
	}
}
