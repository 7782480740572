.custom_table_main_container {
	flex: 1 1 10px;
	height: 10px;
	.custom_table_container {
		height: 100%;
		position: relative;
		overflow: auto;
		border-radius: 6px;
		table {
			width: 100%;
			overflow: auto;
			border-collapse: separate;
			border-spacing: 0;
			border-collapse: initial;
			position: relative;
			z-index: 2;
			thead {
				tr {
					th {
						font-size: 15px;
						font-weight: 400;
						color: var(--white);
						padding: 6px 0;
						position: sticky;
						top: 0;
						z-index: 10;
						white-space: nowrap;
						text-align: center;
						font-family: "regular";
						background-color: var(--tableHeader);
						&:first-child {
							border-radius: 6px 0 0 0;
						}
						&:last-child {
							border-radius: 0 6px 0 0;
						}
						&:last-child {
							.custom_table_th {
								border-right: none;
							}
						}
						.custom_table_th {
							border-right: 1px solid var(--white);
							padding: 2px 10px;
						}
						&.sortable {
							cursor: pointer;
						}
						.sort_icon_container {
							position: relative;
							height: 15px;
							display: inline-block;
							vertical-align: middle;
							> svg {
								height: 13px;
								width: 13px;
								position: absolute;
								top: 0px;
								left: 0px;
								margin-left: 25px;
							}
						}
					}
				}
			}
			tbody {
				tr {
					td {
						padding: 5px 10px;
						color: var(--textColor);
						word-break: break-all;
						word-break: break-word;
						white-space: normal;
						text-align: center;
						.active{
							cursor: pointer;
						}
					}
					&:nth-child(odd) {
						td:nth-child(odd) {
							background-color: var(--white);
						}
						td:nth-child(even) {
							background-color: #f8f8f8;
						}
					}
					&:nth-child(even) {
						td {
							background-color: #ebebeb;
						}
					}
				}
			}
		}
		.custom_table_no_data {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			left: calc(50% - 25px);
			bottom: 0;
			z-index: 1;
			height: auto;
		}
	}
}
