html {
	height: 100%;
	width: 100%;
}
body {
	height: 100%;
	width: 100%;
	user-select: none;
	color: var(--textColor);

	// font-sizes//
	.font_15 {
		font-size: 15px !important;
	}
	.font_16 {
		font-size: 16px;
	}
	.font_26 {
		font-size: 26px;
	}
	.primary_btn {
		background-color: var(--themeColor);
		border: 1px solid var(--themeColor);
		border-radius: 4px;
		box-shadow: none !important;
		color: var(--white);
		min-height: 32px;
		outline: none !important;
		padding: 0 20px;
		&.forward_btn {
			border: 1px solid var(--primaryGreen);
			background-color: var(--primaryGreen);
		}
		&:disabled,
		&[disabled] {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
	.primary_border_btn {
		background-color: var(--white);
		border: 1px solid var(--themeColor);
		border-radius: 4px;
		box-shadow: none !important;
		color: var(--themeColor);
		min-height: 32px;
		outline: none !important;
		padding: 0 20px;
		&:disabled,
		&[disabled] {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
	.secondary_border_btn {
		min-height: 32px;
		background-color: var(--white);
		outline: none !important;
		border: 1px solid var(--textColor);
		border-radius: 4px;
		box-shadow: none !important;
		padding: 0 20px;
		color: var(--textColor);
		&:disabled,
		&[disabled] {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	// ------width and flex width-------//
	.flex_1_1_10_limit {
		flex: 1 1 10px;
		height: 10px;
	}
	.min_width_65 {
		min-width: 65px;
	}
	.min_width_100 {
		min-width: 100px;
	}
	.min_width_120 {
		min-width: 120px;
	}
	.min_width_130 {
		min-width: 130px;
	}
	.min_width_175 {
		min-width: 175px;
	}
	.max_width_210 {
		max-width: 210px;
	}
	.height_70 {
		height: 70px;
	}
	.min_height_36 {
		min-height: 36px;
	}
	.min_height_40 {
		min-height: 40px;
	}
	.flex_1_1_10 {
		flex: 1 1 10px;
	}
	.cursor_pointer {
		cursor: pointer;
	}
	.cursor_not_allowed {
		cursor: not-allowed;
	}
	.invalid_message {
		color: var(--invalid);
		font-size: 12px;
		position: absolute;
		right: 0;
		top: 0;
		&.invalid_top_minus_16 {
			top: -16px;
		}
		&.invalid_left_0 {
			left: 0;
		}
	}
	.word_break {
		word-break: break-all;
		word-break: break-word;
		white-space: pre-wrap;
	}
	.disable_button {
		cursor: not-allowed;
	}
	//---------custom padding----------//
	.pl_2 {
		padding-left: 2px;
	}
	.padding_right_30 {
		padding-right: 30px !important;
	}
	.padding_right_40 {
		padding-right: 40px !important;
	}
	.padding_right_65 {
		padding-right: 65px !important;
	}
	//---------main-backgrounds----------------//
	.main_outer_bg {
		background-image: url("../public/images/registration-bg.png");
		background-position: left;
		background-repeat: no-repeat;
		background-size: 30% 100%;
	}
	.overview_inner_bg {
		background-image: url("../public/images/inner-bg.png");
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.overview_outer_border_box {
		border-top: 1px solid var(--themeColor);
		border-bottom: 1px solid var(--themeColor);
		margin: 20px 0;
	}
	//---------Custom-form----------------//
	.custom_main_form {
		.custom_checkbox {
			-webkit-appearance: none;
			min-width: 24px;
			min-height: 24px;
			border-radius: 4px;
			display: inline-block;
			background-color: var(--white);
			border: 1px solid var(--themeColor);
		}
		.custom_checkbox:after {
			content: "";
			display: inline-block;
			position: relative;
			top: 0px;
			width: 7px;
			height: 13px;
			border-bottom: 1px solid var(--white);
			border-right: 1px solid var(--white);
			right: -8px;
			-webkit-transform: rotate(45deg);
		}
		input[type="checkbox"]:checked {
			background-color: var(--themeColor);
			outline: none;
			border: 1px solid var(--themeColor);
		}
		.custom_input_label {
			font-size: 15px;
			color: var(--textColor);
			font-family: "medium";
			padding-left: 3px;
			margin-bottom: 5px;
			&.register_form_label {
				font-family: "regular";
				color: var(--secondaryTextColor);
			}
		}
		.custom_input_field_container {
			.custom_input_field {
				width: 100%;
				padding: 0 15px;
				box-shadow: 0px 0px 2px 0px var(--boxShadow) inset;
				min-height: 36px;
				outline: none;
				border-radius: 4px;
				border: 1px solid var(--inputBoxBorder);
				background-color: var(--white);
				&.form_input {
					background-color: var(--mainBodyBg);
				}
				&:disabled,
				&[disabled] {
					background-color: var(--disabledFormField) !important;
					cursor: not-allowed;
				}
			}
			.data_selector {
				width: 100%;
				> div:nth-of-type(1) {
					width: 100%;
					padding: 0 15px;
					box-shadow: 0px 0px 2px 0px var(--boxShadow) inset;
					min-height: 36px;
					outline: none;
					border-radius: 4px;
					border: 1px solid var(--inputBoxBorder);
					background-color: var(--white);
				}
				> div:nth-of-type(1):hover {
					border-color: var(--inputBoxBorder) !important;
					.ant-select-selector {
						border-color: var(--inputBoxBorder) !important;
					}
				}
				&.form_input {
					> div:nth-of-type(1) {
						background-color: var(--mainBodyBg);
						border-color: var(--inputBoxBorder) !important;
						> span:nth-of-type(1) {
							padding-right: 15px;
							line-height: 35px;
						}
						> span:nth-of-type(2) {
							color: var(--textColor) !important;
							line-height: 35px;
						}
					}
				}
				.ant-select-arrow {
					right: 10px;
				}
				.ant-select-clear {
					font-size: 10px;
					margin-top: -7px;
					> span {
						> svg {
							width: 15px;
							height: 15px;
						}
					}
				}
			}
			.custom_input_text_area {
				width: 100%;
				box-shadow: 0px 0px 2px 0px var(--boxShadow) inset;
				border: 1px solid var(--inputBoxBorder);
				min-height: 40px;
				padding: 10px 15px;
				display: inline-block;
				vertical-align: top;
				resize: none;
				outline: none;
				box-shadow: none;
				border-radius: 4px;
				&.answer_option {
					border: none;
					max-height: 42px;
				}
				&:disabled,
				&[disabled] {
					background-color: var(--disabledFormField);
					cursor: not-allowed;
				}
			}
			.custom_input_field_icon {
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				display: flex;
				align-items: center;
				&.right_15 {
					right: 15px;
				}
				.attach_svg {
					max-height: 18px;
				}
			}
			.custom_radio_group_container {
				width: 100%;
				.custom_radio_btn {
					width: 100%;
					> span:nth-of-type(1) {
						margin-left: 20px;
					}
					> span:nth-of-type(2) {
						width: 100%;
						padding: 0;
					}
					.ant-radio-inner {
						border: 2px solid var(--themeColor) !important;
					}
					.ant-radio-checked .ant-radio-inner {
						border-color: var(--themeColor) !important;
						background-color: var(--themeColor) !important;
					}
					.ant-radio-checked .ant-radio-inner:after {
						background-color: var(--white) !important;
					}
					.ant-radio:hover .ant-radio-inner {
						border-color: var(--themeColor) !important;
					}
					.ant-radio-checked::after {
						border: 1px solid var(--themeColor) !important;
					}
				}
			}
		}
		.custom_option_answer {
			width: 100%;
			display: inline-block;
		}
		.custom_option_answer_content {
			width: 100%;
			display: inline-block;
			vertical-align: middle;
		}
		.custom_add_option {
			min-width: 40px;
			display: inline-block;
			.attach_svg {
				max-height: 22px;
			}
		}
	}
	.custom_secondary_form {
		.data_selector {
			width: 100%;
			max-width: 150px;
			> div:nth-of-type(1) {
				width: 100%;
				padding: 0 15px;
				box-shadow: 0px 0px 2px 0px var(--boxShadow) inset;
				min-height: 30px;
				outline: none;
				border-radius: 4px;
				border: 1px solid var(--themeLightBorder);
				background-color: var(--white);
				> span:nth-of-type(2) {
					color: var(--themeColor);
				}
			}
			> div:nth-of-type(1):hover {
				border-color: var(--themeLightBorder) !important;
				.ant-select-selector {
					border-color: var(--themeLightBorder) !important;
				}
			}
			&.form_input {
				> div:nth-of-type(1) {
					background-color: var(--white);
					border-color: var(--themeLightBorder) !important;
					max-height: 30px;
				}
			}
		}
	}
	//---------custom antd modal----------//
	.ant-btn {
		min-height: 32px;
		min-width: 90px;
		background-color: var(--white);
		color: var(--themeColor);
		padding: 0px 25px;
		outline: none !important;
		box-shadow: none !important;
		border-radius: 4px;
		&.ant-btn-default {
			color: var(--textColor) !important;
			border-color: var(--textColor) !important;
		}
		&.ant-btn-primary {
			background-color: var(--themeColor);
			color: var(--white) !important;
			&.ant-btn-primary:hover {
				background-color: var(--themeColor) !important;
			}
		}
		&.ant-btn-dangerous {
			color: var(--white) !important;
			background-color: var(--themeColor) !important;
			border-color: var(--themeColor) !important;
		}
	}
	.ant-modal-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent !important;
		.ant-modal {
			top: auto;
			padding-bottom: 0;
			&.question_create_update_modal {
				width: 100% !important;
				max-width: 820px !important;
				.ant-modal-content {
					display: flex;
					flex-direction: column;
					min-height: calc(100vh - (78px + 62px));
					padding: 25px;
				}
			}
			&.generate_link_pop_up_screen {
				width: 100% !important;
				max-width: 950px !important;
				.ant-modal-header {
					margin-bottom: 5px;
				}
			}
			.ant-modal-content {
				width: 100%;
				overflow: auto;
				padding: 30px 10px;
				.ant-modal-header {
					margin-bottom: 15px;
					border-bottom: none;
					.ant-modal-title {
						color: var(--textColor);
						font-size: 22px;
						font-family: "medium";
						font-weight: inherit;
						padding-left: 20px;
					}
				}
				.ant-modal-body {
					display: flex;
					flex-direction: column;
					flex: 1 1 10px;
					height: 100%;
					overflow: auto;
					justify-content: center;
					.ant-modal-confirm-body-wrapper {
						padding: 0 25px;
						.ant-modal-confirm-btns {
							padding-top: 5px;
						}
					}
				}
				.ant-modal-footer {
					padding: 10px 15px;
					border-top: none;
				}
			}
		}
	}
}
::-webkit-input-placeholder {
	color: var(--textColor);
}
::-moz-placeholder {
	color: var(--textColor);
	opacity: 1;
}
/* Microsoft Edge */
::-ms-input-placeholder {
	color: var(--textColor);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
input::-ms-reveal {
	display: none;
}
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	background: var(--themeColor);
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: var(--themeColor);
}
.breadcrumb {
	background: transparent;
	width: auto;
  	float: left;
	.ant-breadcrumb-link {
		font-weight: bold;
		font-size: 16px;
		color: #716f6e;
		a {
			color: #f15623;
		}
	}
}
.red-star {
	color: #f15623;
	margin-right: 5px;
}