.main_container {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	.custom_header {
		background-color: var(--white);
		display: flex;
		align-items: center;
	}
	.main_content_container {
		flex: 1 1 10px;
		height: 10px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		background-color: var(--white);
		// --------- Main overview frame ----------- //
		.overview_container {
			-webkit-box-flex: 1;
			-ms-flex: 1 1 10px;
			flex: 1 1 10px;
			width: 10px;
			height: 100%;
			overflow: auto;
			padding: 0 20px 10px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			.overview_content_box {
				-webkit-box-flex: 1;
				-ms-flex: 1 1 10px;
				flex: 1 1 10px;
				height: 10px;
				padding: 0 10px;
				.overview_content_box_content {
					overflow: auto;
					height: 100%;
					padding: 0px 20px;
				}
			}
		}
	}
}
