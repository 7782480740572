.student_module_overview_bg_container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.student_module_overview_content_box {
		height: 100%;
		max-height: calc(100% - 120px);
		overflow: auto;
		margin: 0 60px;
		border-radius: 8px;
		.heading_text {
			font-size: 30px;
			font-family: "bold";
			color: var(--themeColor);
		}
		.sub_text {
			font-size: 16px;
			color: var(--textColor);
		}
		.logo_container {
			.logo_img {
				max-height: 26px;
			}
		}
	}
}
