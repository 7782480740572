.date-picker-wrap{
    .ant-space {
        width: 100%;
    }
    .ant-picker{
        width: 100%;
        padding: 6px 11px 6px;
        background-color: var(--mainBodyBg);
        border-color: var(--inputBoxBorder) !important;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 0px var(--boxShadow) inset;
    }
}