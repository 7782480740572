.student_module_overview_main_container {
	.logo_container {
		text-align: right;
		padding: 20px;
		.logo_img {
			max-height: 26px;
		}
	}
	.student_module_overview_content_box {
		height: 100%;
		max-height: calc(100% - (65px + 60px));
		overflow: auto;
		margin: 0 60px;
		border-radius: 8px;
		background-color: var(--white);
		box-shadow: 0px 0px 9px 0px var(--boxShadow);
		.student_module_overview_content_box_content {
			height: 100%;
			padding: 0 30px;
			display: flex;
			flex-direction: column;
			overflow: auto;
			.heading_text {
				font-size: 26px;
				font-family: "medium";
				color: var(--black);
			}
			.instructions_box {
				flex: 1 1 10px;
				overflow: auto;
				.instructions_box_content {
					.sub_header {
						font-size: 18px;
						color: var(--secondaryTextColor);
					}
					.instruction {
						font-size: 16px;
						padding: 5px;
						color: var(--textColor);
					}
				}
				.button_container {
					width: 100%;
					padding: 0 30px;
				}
			}
		}
	}
}
