.custom_sidebar_container {
	height: 100%;
	width: var(--sidebarWidth);
	-webkit-box-flex: 0;
	-ms-flex: 0 0 var(--sidebarWidth);
	flex: 0 0 var(--sidebarWidth);
	position: relative;
	z-index: 99;
	.custom_sidebar {
		height: 100%;
		box-shadow: 0px 4px 4px 0px var(--boxShadow);
		background-color: var(--white);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		.sidebar_route_items {
			-webkit-box-flex: 1;
			-ms-flex: 1 1 10px;
			flex: 1 1 10px;
			height: 10px;
			overflow: auto;
			padding-top: 38px;
			.sidebar_menu_item {
				cursor: pointer;
				position: relative;
				margin-top: 5px;
				&.sidebar_menu_item_active {
					border-radius: 0px 8px 8px 0px;
					box-shadow: 0px 0px 2px 0px var(--themeColor) inset;
					.sidebar_item_content {
						.sidebar_item_icon {
							.svg_icon {
								fill: var(--themeColor);
							}
						}
					}
				}
				&.sidebar_menu_item_active::after {
					width: 6px;
					content: "";
					top: 0;
					right: 0;
					bottom: 0;
					position: absolute;
					background-color: var(--themeColor);
					border-radius: 0px 8px 8px 0px;
				}
				.sidebar_item_content {
					display: flex;
					align-items: center;
					padding: 8px 30px;
					.sidebar_item_icon {
						width: 30px;
						height: 30px;
						.svg_icon {
							max-width: 26px;
							max-height: 30px;
							fill: var(--textColor);
							&.result {
								max-width: 20px;
							}
						}
					}
					.sidebar_item_label {
						flex: 1 1 10px;
						width: 10px;
						margin-top: 2px;
						word-break: break-all;
						word-break: break-word;
						white-space: pre-wrap;
						padding-left: 15px;
						font-size: 16px;
					}
				}
			}
		}
		.sidebar_user_toggles {
			padding: 10px 20px 40px;
		}
	}
}
